<template>
	<div class="page-home">
		<div class="page-head"><span>{{$t('liveplatform.infoCard.pageTitle')}}</span></div>
		<div class="page-list">
			<div class="item" @click="$router.push({name: 'LiveInformationcardCreate'})">
				<div class="item-main item-add"><i class="el-icon-plus"></i></div>
				<div class="item-name">{{$t('liveplatform.infoCard.createBtn')}}</div>
			</div>
			<div class="item" v-for="(item,index) in pageList" :key="index" @click="$router.push({name: 'LiveInformationcardEdit',params:{id:item.info_card_id}})">
				<div class="item-main">
					<img :src="item.image_url" alt="">
				</div>
				<div class="item-name">{{item.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getInfoCardList } from '@/api/liveplatform';
	export default {
		data() {
			return {
				pageList: [],
				pageCurr: 1,
				pageSize: 20,
				pageCount: 0,
			}
		},
		created() {
			this.getPageList();
		},
		methods: {
			getPageList(){
				let param = {
					page_index: (this.pageCurr-1)*this.pageSize,
					page_size: this.pageSize,
					app_key: this.$store.getters.appKey
				}
				getInfoCardList(param).then(response => {
					this.pageList = response.data.list;
					this.pageCount = parseInt(response.data.total);
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-home {
		background-color: $--live-background-default;
		padding: 20px;
		border-radius: 6px;
		.page-head {
			padding: 14px 24px;
			line-height: 35px;
			color: #FFFFFF;
			font-size: 20px;
			font-weight: 600;
		}
		.page-list {
			.item {
				width: 95px;
				margin: 12px;
				display: inline-block;
				cursor: pointer;
				.item-main {
					width: 95px;
					height: 338px;
					border-radius: 6px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: rgba(0,0,0,.25);
					img {
						width: 100%;
					}
				}
				.item-add {
					background-color: $--live-gray-white-16;
					.el-icon-plus {
						color: #FFFFFF;
						font-size: 24px;
					}
				}
				.item-name {
					color: #FFFFFF;
					font-size: 14px;
					margin-top: 16px;
					height: 26px;
					line-height: 19px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: center;
				}
			}
		}
	}
</style>
